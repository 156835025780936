import type { Ref } from 'vue'
import { computed } from 'vue'
import { APPS_THAT_NEED_AUTH } from '@autobid/ui/constants/APPS_THAT_NEED_AUTH'
import type { Restriction } from '@autobid/ui/types/components/AuctionsData'
import { useToken } from '@autobid/nuxt-auth/src/composables/useToken'
import { APP_ID_NAME_MAP } from '../constants/APP_ID_NAME_MAP'
import { useCheckUserPermission } from './useCheckUserPermission'

type UseAccessToAuctionParams = {
  restrictions?: Restriction
  appId?: number
  isAuthed: Ref<boolean>
}

type CheckAccessToAuctionParams = Pick<
  UseAccessToAuctionParams,
  'appId' | 'restrictions'
> & {
  userCountryId: number | undefined
  isAuthed: boolean
  t: (key: string) => string
  checkUserPermission: (permissionName: string, appId?: number) => boolean
}

export const checkAccessToAuction = ({
  restrictions,
  appId,
  userCountryId,
  isAuthed,
  t,
  checkUserPermission
}: CheckAccessToAuctionParams) => {
  const restrictedCountriesIds =
    restrictions?.permittedCountriesIDs?.map(Number) ?? []
  if (
    restrictedCountriesIds.length &&
    (!userCountryId || !restrictedCountriesIds.includes(+userCountryId))
  ) {
    return {
      statusCode: isAuthed ? 403 : 401,
      statusMessage: isAuthed
        ? t('auction.middleware.not-permitted-country')
        : t('error-page.401.description')
    } as const
  }

  if (
    !isAuthed &&
    !restrictions?.permissionRequired?.length &&
    !APPS_THAT_NEED_AUTH.includes(APP_ID_NAME_MAP[appId])
  ) {
    return {
      statusCode: 200
    } as const
  }

  if (!isAuthed) {
    return {
      statusCode: 401,
      statusMessage: t('error-page.401.description')
    } as const
  }

  const hasAppAccess = checkUserPermission('login', appId)
  if (!hasAppAccess) {
    return {
      statusCode: 403,
      statusMessage: t('auction.middleware.no-access')
    } as const
  }

  if (
    restrictions?.permissionRequired &&
    !checkUserPermission(restrictions?.permissionRequired, appId)
  ) {
    return {
      statusCode: 403,
      statusMessage: t('auction.middleware.no-access')
    } as const
  }

  return {
    statusCode: 200
  } as const
}

export const useAccessToAuction = ({
  restrictions,
  appId,
  isAuthed
}: UseAccessToAuctionParams) => {
  const checkAccessToAuction = useCheckAccessToAuction()

  return computed(() => {
    return checkAccessToAuction(restrictions, appId, unref(isAuthed))
  })
}

export const useCheckAccessToAuction = () => {
  const { t } = useI18n()
  const { checkUserPermission } = useCheckUserPermission()
  const { tokenData } = useToken()

  return (restrictions: Restriction, appId: number, isAuthed: boolean) => {
    return checkAccessToAuction({
      restrictions,
      appId,
      userCountryId: tokenData.value?.countryId,
      isAuthed,
      t,
      checkUserPermission
    })
  }
}
